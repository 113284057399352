<script>
import Btn from "@/components/Btn";
    export default {
        name: 'Modal',
        components:{
            Btn
        },
        data(){
            return{
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            returnBack(){
                this.$emit("returnBack");
            }
        },
    };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-err">
      <div class="modal-error"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
            <div class="modelheader">
                <!-- <slot name="header">
                    <span></span>
                </slot> -->

                <button
                    type="button"
                    class="btn-close-err"
                    @click="close"
                    aria-label="Close modal"
                >
                    x
                </button>
            </div>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <div class="form-field form-field__time_1">
                <div class="form-field-input-wrapper">
                    <span class="addtext">ordrenummeret eksisterer ikke</span>
                </div>
            </div>
          </slot>
        </section>

        <footer class="modal-footer">
            <btn label="Ok" width="100px" right @click="returnBack" :disabled="false"/>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style>
    .modal-backdrop-err {
        /* position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; */
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        padding:4em;
    }
    
    .btn--right{
        position: relative;
        z-index:0
    }
    .modal-error {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 35%;
        box-shadow: none;
        border-radius: 10px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #56bae0;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 600;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
    }

    .btn-close-err {
        position: absolute;
        top: -7px;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #56bae0;
        background: transparent;
    }

    .btn--green {
        color: white;
        font-size: 16px;
        background: #f15726;
        border-radius: 8px;
        padding: 13px 20px;
        display: flex;
        margin: 0 auto;
        border: none;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .create-container {
        padding: 30px 0;
        max-width: 812px;
        margin: 0 auto;
    }
    .form-field__time_1{
        width: 100%;
        /* margin-left: 20px; */
        display: flex;
        justify-content: center;

    }
    .for-next{
        margin-bottom: 12px; 
    }
    .addtext{
        color: #000;
    }

    @media (max-width:820px){
         .modal-error {
            width: 50%;
        }
        
    }
    @media (max-width:520px){
        .modal-error {
            width: 100%;
        }
    }
</style>
