<template>
    <div>
        <Errormodal
            v-show="isModalVisible"
            @close="closeModal"
            @returnBack="returnBack"
        />
    </div>
</template>

<script>
    import Errormodal from '@/components/ErrorModel.vue';
    export default {
        name: "NotFoundPage",
        components: {
            Errormodal
        },
        data() {
            return {
                isModalVisible:false
            };   
        },
        created(){
            this.isModalVisible = true;   
        },
        methods:{
            closeModal() {
                this.isModalVisible = false;
            },
            returnBack(){
                window.history.go(-2);
            }
        }
    };
</script>


